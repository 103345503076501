<mat-card>
  <!--  <mat-card-title>Thank You</mat-card-title>-->
    <mat-card-content>                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">Thank you for submitting your TOBA application. We’ll get this processed for you in the near future.</p>
      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">You’ll be contacted shortly by Matthew Dover from the Renovation Underwriting team, who will collect the names and contact details of your team of placing brokers so they can be provided with profiles for our on-line trading platform.</p>
      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">We will also contact you to arrange for the delivery of our CPD session. This will give your brokers a better understanding of the products we provide, and the environment in which these products are purchased. This session would either be delivered remotely or at your offices at a time of your convenience.</p>
      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">If you have not heard from us within 5 working days, please contact us on 0333 358 0006 for an update on your TOBA status.</p>

      <p>Visit <a href="https://www.renovationunderwriting.com/">Renovation Underwriting</a></p>
    </mat-card-content>
  </mat-card>

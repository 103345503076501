import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

function atLeastOneCheckedValidator(minRequired = 1): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const controls = formGroup.controls;
    const checkedCount = Object.keys(controls).filter(key => controls[key].value.checked).length;

    return checkedCount >= minRequired ? null : { atLeastOneCheckedError: true };
  };
}

// function atLeastOneCheckedValidator(minRequired = 1): ValidationErrors | null {
//   return (control: AbstractControl): ValidationErrors | null => {
//     const checked = (control as FormArray).controls.filter(ctrl => ctrl.value);
//     if (checked.length < minRequired) {
//       return {
//         atLeastOneCheckedError: true
//       };
//     }
//     return null;
//   };
// }
@Component({
  selector: 'app-toba',
  templateUrl: './toba.component.html',
  styleUrls: ['./toba.component.scss']
})
export class TobaComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private router: Router
  ) { }

  // brokerOptions = [
  //   {name: 'Plum Underwriting'},
  //   {name: 'Renovation Plan'},
  //   {name: 'Hiscox'},
  //   {name: 'Azur'},
  //   {name: 'Chubb'},
  //   {name: 'Other Broker'},
  // ];
  //   selectedBroker = '';
  errorMessage = null;
  referalOptions = [
    { name: 'Employer/CoWorker' },
    { name: 'Sector referral' },
    { name: 'RUL approach' },
    { name: 'Search engine' },
    { name: 'Social media' },
    { name: 'Other' },
  ];
  selectedReferal = '';
  commercialOptions = [
    { name: 'Yes' },
    { name: 'No' }
  ];

  options = [
    { label: 'Broker Network', value: 'broker_network' },
    { label: 'Compass', value: 'compass' },
    { label: 'Cobra', value: 'cobra' },
    { label: 'Willis', value: 'willis' },
    { label: 'Hedron', value: 'hedron' },
    { label: 'Brokerbility', value: 'brokerbility' },
    { label: 'UNA', value: 'UNA' },
    { label: 'Bravo', value: 'bravo' },
    { label: 'Ten', value: 'ten' },
    { label: 'Momentum', value: 'momentum' },
    { label: 'Not a network member', value: 'not_a_network_member' },
  ];
  networkOptions = new FormArray(
    this.options.map(option => {
      return new FormGroup({
        label: new FormControl(option.label),
        value: new FormControl(option.value),
        checked: new FormControl(false)
      });
    }), atLeastOneCheckedValidator()
  );
  commercialSelected = '';
  networthOptions = [
    { name: 'Below 500' },
    { name: '501-1000' },
    { name: '1001-2000' },
    { name: 'Above 2000' }
  ];
  networthSelected = '';
  propertyPolicyOptions = [
    { name: 'Below 500' },
    { name: '501-1000' },
    { name: '1001-2000' },
    { name: 'Above 2000' }
  ];
  propertyPolicySelected = '';
  worksInsuranceOptions = [
    { name: 'Yes' },
    { name: 'No' }
  ];
  worksInsuranceSelected = '';
  subBroke = '';
  adminFee = '';
  addOnsV = ''
  isLoading = false;
  firstFormGroup = new FormGroup({
    fullName: new FormControl('', Validators.required),
    position: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    telephoneDDI: new FormControl('', [Validators.required, Validators.pattern('(((\\+44)? ?(\\(0\\))? ?)|(0))( ?[0-9]{3,4}){3}')]),
    referralStream: new FormControl('', Validators.required),
    otherReferal: new FormControl(''),
    nameOfFirm: new FormControl('', Validators.required),
    website: new FormControl('', [Validators.required, Validators.pattern('')]),
    telephoneHead: new FormControl('', [Validators.required, Validators.pattern('(((\\+44)? ?(\\(0\\))? ?)|(0))( ?[0-9]{3,4}){3}')]),
    fcaRefNum: new FormControl('', [Validators.minLength(6), Validators.maxLength(6)]),
    addressOne: new FormControl('', Validators.required),
    addressTwo: new FormControl(''),
    town: new FormControl('', Validators.required),
    postCode: new FormControl('', Validators.required),
    networkoptions: this.networkOptions

  });
  secondFormGroup = new FormGroup({
    accountsFullName: new FormControl('', Validators.required),
    accountsPosition: new FormControl('', Validators.required),
    accountsEmail: new FormControl('', [Validators.required, Validators.email]),
    telephoneDDIAccounts: new FormControl('', [Validators.required, Validators.pattern('(((\\+44)? ?(\\(0\\))? ?)|(0))( ?[0-9]{3,4}){3}')]),
    agencyFullName: new FormControl('', Validators.required),
    agencyPosition: new FormControl('', Validators.required),
    agencyEmail: new FormControl('', [Validators.required, Validators.email]),
    telephoneDDIAgency: new FormControl('', [Validators.required, Validators.pattern('(((\\+44)? ?(\\(0\\))? ?)|(0))( ?[0-9]{3,4}){3}')]),
    claimsFullName: new FormControl(''),
    claimsPosition: new FormControl(''),
    claimsEmail: new FormControl('', [Validators.email]),
    telephoneDDIclaims: new FormControl('', [Validators.pattern('(((\\+44)? ?(\\(0\\))? ?)|(0))( ?[0-9]{3,4}){3}')]),
    marketFullName: new FormControl(''),
    marketPosition: new FormControl(''),
    marketEmail: new FormControl('', [Validators.email]),
    telephoneDDImarket: new FormControl('', [Validators.pattern('(((\\+44)? ?(\\(0\\))? ?)|(0))( ?[0-9]{3,4}){3}')]),
  });
  thirdFormGroup = new FormGroup({
    commercialConstructionSpecialism: new FormControl('', Validators.required),
    highNetWorthPolicies: new FormControl('', Validators.required),
    propertyOwnerPolicies: new FormControl('', Validators.required),
    approachedDeal: new FormControl(null, Validators.required),
    approachedDealYes: new FormControl(''),
    subBroke: new FormControl('', Validators.required),
    adminFeeForm: new FormControl('', Validators.required),
    adminFeeYes: new FormControl(''),
    addOns: new FormControl('', Validators.required),
    addOnsyes: new FormControl(''),
    // whichMarkets: new FormControl('', Validators.required),
    // otherBroker: new FormControl('', Validators.required),
    worksExpert: new FormControl('', Validators.required),
    checkboxAgreeCPD: new FormControl('', Validators.requiredTrue),
    brokerageTrainingContact: new FormControl('', Validators.required),
    checkboxAgreeTerms: new FormControl('', Validators.requiredTrue),
    signatureName: new FormControl('', Validators.required),
    checkboxAgreeSignature: new FormControl('', Validators.requiredTrue),
  });

  @ViewChild('sigCanvas', { static: false }) myCanvas: ElementRef<HTMLCanvasElement>;
  public context: CanvasRenderingContext2D;

  // submit() {
  //   this.api.submitForm(this.firstFormGroup.value).subscribe((res) => {
  //     console.log(res);
  //   }, err => {
  //     console.log(err.message.toLocaleString());
  //   });
  // }
  // submitStep2() {
  //   console.log(this.secondFormGroup.value);
  // }
  brokerType: string;

  ngOnInit() {
    this.networkOptions.markAsUntouched();
    this.networkOptions.markAsPristine();
  }
  firstNext() {
    console.log("hello")
  }
  submitStep3() {
    this.isLoading = true;
    const signature = document.getElementById('sigCanvas') as HTMLCanvasElement;
    const signatureBase64 = signature.toDataURL();
    const tobaData = {
      ...this.firstFormGroup.value, ...this.secondFormGroup.value, ...this.thirdFormGroup.value,
      signatureBase64
    };
    this.api.submitForm(tobaData).subscribe((res) => {
      this.isLoading = false;
      this.router.navigate(['/thank-you']);
    }, err => {
      // Check if the error has a response and a message
      this.isLoading = false;
      if (err.error && err.error.message) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = 'An unexpected error occurred';
      }
    });
  }

  sign(e): void {
    this.context = this.myCanvas.nativeElement.getContext('2d');
    this.context.clearRect(0, 0, 400, 60);
    this.context.font = '40px Satisfy';
    this.context.fillText(e.target.value, 40, 40);
  }

  nameUpdate(e): void {
    this.thirdFormGroup.controls.signatureName.setValue(e.target.value);
    this.sign(e);
  }

}

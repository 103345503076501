import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TobaComponent} from "./public/toba/toba.component";
import {ThankYouComponent} from "./public/thank-you/thank-you.component";
import {PublicComponent} from "./public/public.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import { OnboardingComponent } from './public/onboarding/onboarding.component';
import { ThankYouOnboardingComponent } from './public/thank-you-onboarding/thank-you-onboarding.component';


const routes: Routes = [
  {path: '', component: PublicComponent, children: [
      {path: 'toba', component: TobaComponent},
      {path: 'thank-you', component: ThankYouComponent},
      {path: 'thank-you-onboarding', component: ThankYouOnboardingComponent},
      {path: 'onboarding', component: OnboardingComponent},
    ]},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

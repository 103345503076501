import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  passedData;
  api = environment.api;

  constructor(
    private http: HttpClient
  ) { }

  submitForm(data): Observable<any> {
    this.passedData = data;
    return this.http.post(this.api + 'v1/toba/tobaData', data);
  }


  submitOnboardingForm(data): Observable<any> {
    this.passedData = data;
    return this.http.post(this.api + 'v1/toba/onboarding', data);
  }

  getData() {
    return this.passedData;
  }

  submitHubspot(data): Observable<any> {
    return this.http.post(this.api + 'hubspot', data);
  }
}

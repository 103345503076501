import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatStepperModule} from '@angular/material/stepper';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import { PublicComponent } from './public/public.component';
import {ThankYouComponent} from "./public/thank-you/thank-you.component";
import {TobaComponent} from "./public/toba/toba.component";
import { NotFoundComponent } from './not-found/not-found.component';
import { OnboardingComponent } from './public/onboarding/onboarding.component';
import { ThankYouOnboardingComponent } from './public/thank-you-onboarding/thank-you-onboarding.component';

@NgModule({
  declarations: [
    AppComponent,
    ThankYouComponent,
    ThankYouOnboardingComponent,
    TobaComponent,
    OnboardingComponent,
    PublicComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

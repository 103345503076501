<mat-horizontal-stepper
  labelPosition="bottom"
  [linear]="true"
  #stepper
  style=""
  id="formStepper"
>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Contact Details</ng-template>
      <div>
        <h2>Onboarding for Geo Brokers and BRAVO Premier Network members</h2>
        <!-- <p>The online Onboarding application process is intended for independent brokers, regulated by the FCA, that are applying on their own behalf.</p>
        <p style="vertical-align: bottom;font-weight: 600;">Thank You.</p> -->
      </div>
      <div class="ui two column stackable grid">
        <div class="sixteen wide column"><h3>Your Details</h3></div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Full Name</mat-label>
            <input
              matInput
              name="name"
              (change)="nameUpdate($event)"
              placeholder="Full Name"
              formControlName="fullName"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Position</mat-label>
            <input
              matInput
              name="position"
              placeholder="Position"
              formControlName="position"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="Email"
              name="email"
              formControlName="email"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Direct Dial</mat-label>
            <input
              matInput
              name="telephone"
              placeholder="Direct Dial"
              formControlName="telephoneDDI"
              required
            />
          </mat-form-field>
        </div>
        <div class="sixteen wide column"><h3>Business Details</h3></div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Name of Firm</mat-label>
            <input
              matInput
              placeholder="Name of Firm"
              formControlName="nameOfFirm"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>FCA Reference Number</mat-label>
            <input
              matInput
              placeholder="FCA Reference Number"
              formControlName="fcaRefNum"
              required
            />
          </mat-form-field>
        </div>
        <div class="sixteen wide column">
          <mat-form-field appearance="outline">
            <mat-label>Website URL</mat-label>
            <input
              matInput
              placeholder="Website URL"
              formControlName="website"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Head Office Telephone</mat-label>
            <input
              matInput
              name="telephone"
              placeholder="Telephone"
              formControlName="telephoneHead"
              required
            />
          </mat-form-field>
        </div>
        <div class="sixteen wide column">
          <h3>Please indicate if you are part of a network</h3>
        </div>
        <div class="sixteen wide column">
          <div
            formArrayName="networkoptions"
            style="display: flex; align-items: center; flex-wrap: wrap"
          >
            <div
              *ngFor="let option of networkOptions.controls; let i = index"
              [formGroupName]="i"
              style="padding-right: 10px"
            >
              <mat-checkbox color="primary" [formControlName]="'checked'">{{
                option.get("label").value
              }}</mat-checkbox>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            firstFormGroup.touched &&
            networkOptions.hasError('atLeastOneCheckedError')
          "
          style="color: red"
        >
          Please make at least one selection
        </div>
        <div class="sixteen wide column">
          <h3>UK Company Registered Address</h3>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Address 1</mat-label>
            <input
              matInput
              placeholder="Address 1"
              formControlName="addressOne"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Address 2</mat-label>
            <input
              matInput
              placeholder="Address 2"
              formControlName="addressTwo"
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Town</mat-label>
            <input
              matInput
              placeholder="Town"
              formControlName="town"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Postcode</mat-label>
            <input
              matInput
              placeholder="Postcode"
              formControlName="postCode"
              required
            />
          </mat-form-field>
        </div>
        <div class="sixteen wide column">
          <h3>How did you hear about us?</h3>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>How did you hear about us?</mat-label>
            <select
              matNativeControl
              [(ngModel)]="selectedReferal"
              formControlName="referralStream"
              required
            >
              <option
                *ngFor="let referal of referalOptions"
                [ngValue]="referal.name"
              >
                {{ referal.name }}
              </option>
            </select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="selectedReferal == 'Other'"
          >
            <input
              matInput
              placeholder="Please Specify"
              formControlName="otherReferal"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="actions">
        <button color="primary" mat-raised-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Company Details</ng-template>
      <div class="ui two column stackable grid">
        <div class="sixteen wide column"><h3>Accounts Contact</h3></div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Full Name</mat-label>
            <input
              matInput
              placeholder="Full Name"
              formControlName="accountsFullName"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Position</mat-label>
            <input
              matInput
              placeholder="Position"
              formControlName="accountsPosition"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="Email"
              formControlName="accountsEmail"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Direct Dial</mat-label>
            <input
              matInput
              name="telephone"
              placeholder="Direct Dial"
              formControlName="telephoneDDIAccounts"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="ui two column stackable grid">
        <div class="sixteen wide column"><h3>Agency Manager</h3></div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Full Name</mat-label>
            <input
              matInput
              placeholder="Full Name"
              formControlName="agencyFullName"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Position</mat-label>
            <input
              matInput
              placeholder="Position"
              formControlName="agencyPosition"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="Email"
              formControlName="agencyEmail"
              required
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Direct Dial</mat-label>
            <input
              matInput
              name="telephone"
              placeholder="Direct Dial"
              formControlName="telephoneDDIAgency"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="ui two column stackable grid">
        <div class="sixteen wide column"><h3>Claims Contact</h3></div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Full Name</mat-label>
            <input
              matInput
              placeholder="Full Name"
              formControlName="claimsFullName"
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Position</mat-label>
            <input
              matInput
              placeholder="Position"
              formControlName="claimsPosition"
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="claimsEmail" />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Direct Dial</mat-label>
            <input
              matInput
              name="telephone"
              placeholder="Direct Dial"
              formControlName="telephoneDDIclaims"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="ui two column stackable grid">
        <div class="sixteen wide column"><h3>Marketing Contact</h3></div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Full Name</mat-label>
            <input
              matInput
              placeholder="Full Name"
              formControlName="marketFullName"
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Position</mat-label>
            <input
              matInput
              placeholder="Position"
              formControlName="marketPosition"
            />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="marketEmail" />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label>Direct Dial</mat-label>
            <input
              matInput
              name="telephone"
              placeholder="Direct Dial"
              formControlName="telephoneDDImarket"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="actions">
        <button color="primary" mat-raised-button matStepperPrevious>
          Back
        </button>
        <button color="primary" mat-raised-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>Information</ng-template>

      <div class="ui two column grid">
        <div class="sixteen wide column"><h3>Fair Value</h3></div>
        <div class="sixteen wide column">
          <mat-form-field appearance="outline">
            <mat-label class="wrapped-text" style="text-wrap: wrap"
              >We don’t automatically permit onward sub-broking of our products
              to other brokers. Is it your intention to sub-broke our
              products?</mat-label
            >
            <select
              matNativeControl
              [(ngModel)]="subBroke"
              formControlName="subBroke"
              required
            >
              <option
                *ngFor="let works of worksInsuranceOptions"
                [ngValue]="works.name"
              >
                {{ works.name }}
              </option>
            </select>
          </mat-form-field>
          <!-- <mat-form-field appearance="outline" *ngIf="worksInsuranceSelected == 'Yes'">
              <input matInput placeholder="Please Specify" formControlName="approachedDealYes">
            </mat-form-field> -->
        </div>
        <div class="sixteen wide column">
          <mat-form-field appearance="outline"
            ><mat-label
              >Will you apply administration fees to our products? If you will,
              please provide details
            </mat-label>
            <select
              matNativeControl
              [(ngModel)]="adminFee"
              formControlName="adminFeeForm"
              required
            >
              <option
                *ngFor="let works of worksInsuranceOptions"
                [ngValue]="works.name"
              >
                {{ works.name }}
              </option>
            </select>
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="adminFee == 'Yes'">
            <input
              id="adminFeeYes"
              matInput
              placeholder="for example – Up to £75 for new business / £25 for any MTA or extension *"
              formControlName="adminFeeYes"
              [required]="adminFee == 'Yes'"
            />
          </mat-form-field>
        </div>
        <div class="sixteen wide column">
          <mat-form-field appearance="outline"
            ><mat-label
              >Will you apply any add-ons in connection with our products? If
              you will, please provide details</mat-label
            >
            <select
              matNativeControl
              [(ngModel)]="addOnsV"
              formControlName="addOns"
              required
            >
              <option
                *ngFor="let works of worksInsuranceOptions"
                [ngValue]="works.name"
              >
                {{ works.name }}
              </option>
            </select>
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="addOnsV == 'Yes'">
            <input
              id="addOnsyes"
              matInput
              placeholder="for example – We offer Legal Expenses policies and we may offer premium finance *"
              formControlName="addOnsyes"
              [required]="addOnsV == 'Yes'"
            />
          </mat-form-field>
        </div>
        <div class="sixteen wide column"><h3>Key Information</h3></div>
        <div class="sixteen wide column">
          <mat-form-field appearance="outline">
            <mat-label
              >Do you have a private clients and/or commercial construction
              specialism?</mat-label
            >
            <select
              matNativeControl
              [(ngModel)]="commercialSelected"
              formControlName="commercialConstructionSpecialism"
              required
            >
              <option
                *ngFor="let option of commercialOptions"
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
          </mat-form-field>
        </div>
        <div class="sixteen wide column">
          <mat-form-field appearance="outline">
            <mat-label
              >Approximately how many mid and high net worth policies do you
              have?</mat-label
            >
            <select
              matNativeControl
              [(ngModel)]="networthSelected"
              formControlName="highNetWorthPolicies"
              required
            >
              <option
                *ngFor="let networth of networthOptions"
                [ngValue]="networth.name"
              >
                {{ networth.name }}
              </option>
            </select>
          </mat-form-field>
        </div>
        <div class="sixteen wide column">
          <mat-form-field appearance="outline">
            <mat-label
              >Approximately how many property owners policies do you
              have?</mat-label
            >
            <select
              matNativeControl
              [(ngModel)]="propertyPolicySelected"
              formControlName="propertyOwnerPolicies"
              required
            >
              <option
                *ngFor="let propertyPolicy of propertyPolicyOptions"
                [ngValue]="propertyPolicy.name"
              >
                {{ propertyPolicy.name }}
              </option>
            </select>
          </mat-form-field>
        </div>
        <div class="sixteen wide column">
          <mat-form-field appearance="outline">
            <mat-label
              >Do you have existing markets for private client and property
              owners contract works insurance?</mat-label
            >
            <select
              matNativeControl
              [(ngModel)]="worksInsuranceSelected"
              formControlName="approachedDeal"
              required
            >
              <option
                *ngFor="let works of worksInsuranceOptions"
                [ngValue]="works.name"
              >
                {{ works.name }}
              </option>
            </select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="worksInsuranceSelected == 'Yes'"
          >
            <input
              matInput
              placeholder="Please Specify"
              formControlName="approachedDealYes"
            />
          </mat-form-field>
        </div>
        <div class="sixteen wide column">
          <mat-form-field appearance="outline">
            <mat-label>Who is the ‘works expert’ in your brokerage?</mat-label>
            <input
              matInput
              placeholder="Who is the ‘works expert’ in your brokerage?"
              formControlName="worksExpert"
              required
            />
          </mat-form-field>
        </div>
        <div class="column"></div>
        <div class="sixteen wide column">
          <mat-checkbox color="primary" formControlName="checkboxAgreeCPD"
            >As part of your TOBA Application, we enrol you into our CPD
            programme. Please tick this box to confirm you are happy with this
            process.</mat-checkbox
          >
          <mat-form-field
            appearance="outline"
            style="margin-bottom: 1.5rem; margin-top: 0.5rem"
          >
            <mat-label>Brokerage Training Contact</mat-label>
            <input
              matInput
              placeholder="Brokerage Training Contact"
              formControlName="brokerageTrainingContact"
              required
            />
          </mat-form-field>
        </div>
        <div class="sixteen wide column">
          <!-- <mat-form-field appearance="outline">
            <mat-label>Full Name - for signature</mat-label>
            <input #signature (keyup)="sign($event)" matInput placeholder="Full Name - for signature" formControlName="signatureName">
          </mat-form-field> -->
          <!-- <mat-label class="signatureLabel">Signature</mat-label>
          <mat-card class="signatureCard">
            <canvas #sigCanvas id="sigCanvas" width="400" height="60"></canvas>
          </mat-card> -->
          <mat-checkbox color="primary" formControlName="checkboxAgreeSignature"
            >I understand this will be used as my official signature for the
            purposes of this documentation.</mat-checkbox
          >
        </div>
        <div class="sixteen wide column">
          <mat-checkbox color="primary" formControlName="checkboxAgreeTerms"
            >By ticking this box, you confirm that you have read and understood
            the TOBA Document, and agree to receive correspondence, email and
            telephone calls from Renovation Underwriting and
            partners.</mat-checkbox
          >
        </div>
        <i
          >If you have not heard from us within 5 working days, please contact
          us on 0333 358 0006 for an update on your TOBA status.</i
        >
      </div>
      <div class="actions">
        <button mat-raised-button color="primary" matStepperPrevious>
          Back
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!thirdFormGroup.valid || isLoading"
          (click)="submitStep3()"
        >
          Send
        </button>

        <div class="loader" *ngIf="isLoading"></div>
        <div class="error-message">
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>

import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-thank-you-onboarding',
  templateUrl: './thank-you-onboarding.component.html',
  styleUrls: ['./thank-you-onboarding.component.scss']
})
export class ThankYouOnboardingComponent implements OnInit {

  data

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.data = this.api.getData();
    console.log(this.data);
  }

}
